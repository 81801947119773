$('[data-menu-toggle]').click(function () {
    $(this).toggleClass('is-active');
    $('[data-menu]').toggleClass('is-open');
});

$('.btn-modal').magnificPopup({
    type: 'inline',
    callbacks: {
        open: function() {
            if (this.currItem.el.data('vacancy')) {
                var vacancy = this.currItem.el.data('vacancy');
                this.currItem.inlineElement.find('#vacancy-name').val(vacancy);
            }
        }
    }
});

$('body').on('click', '[data-dismiss="alert"]', function () {
    $(this).closest('.alert').remove();
});